@font-face {
  font-family: "cnomk";
  src: url('../../fonts/iconfont/cnomk.eot');
  src: url('../../fonts/iconfont/cnomk.eot?#iefix') format('eot'),
    url('../../fonts/iconfont/cnomk.woff2') format('woff2'),
    url('../../fonts/iconfont/cnomk.woff') format('woff'),
    url('../../fonts/iconfont/cnomk.ttf') format('truetype'),
    url('../../fonts/iconfont/cnomk.svg#cnomk') format('svg');
}

@mixin icon-styles {
  font-family: "cnomk";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == alerte {
    $char: "\E001";
  }
  @if $filename == arrow-left {
    $char: "\E002";
  }
  @if $filename == chevron-up {
    $char: "\E003";
  }
  @if $filename == facebook {
    $char: "\E004";
  }
  @if $filename == kernix {
    $char: "\E005";
  }
  @if $filename == linkedin {
    $char: "\E006";
  }
  @if $filename == test {
    $char: "\E007";
  }
  @if $filename == twitter {
    $char: "\E008";
  }

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    } @else {
      @include icon-styles;
    }
    content: icon-char($filename);
  }
}

.icon-alerte {
  @include icon(alerte);
}
.icon-arrow-left {
  @include icon(arrow-left);
}
.icon-chevron-up {
  @include icon(chevron-up);
}
.icon-facebook {
  @include icon(facebook);
}
.icon-kernix {
  @include icon(kernix);
}
.icon-linkedin {
  @include icon(linkedin);
}
.icon-test {
  @include icon(test);
}
.icon-twitter {
  @include icon(twitter);
}
