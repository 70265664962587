// COLOURS
$blue:          #273c75;
$midnight-blue: #192a56;
$dark-grey:     #404040;
$grey:          #8C8C8C;
$light-grey:    #D9D9D9;
$red:           #E4254A;
$green:         #0F837C;
$light-green:   #6ED3CF;
$green-picto:   #3F9B96;
$yellow:        #fbc531;
$orange:        #F86759;
$light-orange:  #F9857A;
$white:         #fff;
$black:         #000;

$primary:     $blue;
$secondary :  $dark-grey;
$tertiary:    $midnight-blue;

$success:    $green;
$info:       $primary;
$warning:    $yellow;
$danger:     $red;

// Complement
$border-color:    #d8d7d5;
$text-color:      $dark-grey;
$body-color:      $black;
$input-color:     $white;

// SIZES
$btn-height: 50px;
$input-height: 40px;
$input-height-lg: 60px;

// Gutter Padding
// $grid-gutter-width: 40px;

// FONTS
@import "mixins/strip-unit";
$font-family-base: 'Overpass', sans-serif;
  // Fz Bootstrap
  $font-size-base: 1rem /* 16px*/;
  $font-value-base: strip-unit($font-size-base);

  // Fz for mixin rem
  $font-size-base-px: (16px*$font-value-base) /* 1rem */;

// BREAK-POINTS
$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 1024px,
  lg: 1280px,
  xl: 1445px
) !default;

// Container
$container-max-widths: (
  sm: 1180px,
  md: 1190px,
  lg: 1200px,
  xl: 1210px
) !default;
