.tooltip {
  .arrow {
    &::before {  }
  }
  // &.bs-tooltip-right {
  //   .arrow { transform: translateX(10px); }
  //   .tooltip-inner { transform: translateX(-10px); }
  // }
  &.bs-tooltip-right {
    .arrow {
      &::before { border-right-color: $white; }
    }
  }


  &.bs-tooltip-top .arrow {
    &::before { border-bottom-color: $white; }
  }
  .tooltip-inner { box-shadow: 0 0 30px 0 rgba($green, 0.2); background-color: $white; font-size: rem(14px); font-weight: normal; font-style: normal; line-height: normal; color: $grey; padding: 20px 20px 20px 20px; border-radius: 0; }
}