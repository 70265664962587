/* ---- Header ---- */
.header-wrap { position: fixed; top: 0; left: 0; right: 0; z-index: 6; transition: .3s all ease;
  //@extend .clearfix;
  padding: rem(20px) 0;
  @include max(xs){ padding: rem(15px) 0; }
  .custom-container {
    @include max(xs){ padding: 0 10px !important; }
    .row {
      @include max(xs){ margin:0;}
      .col-6 {
        @include max(xs){ padding: 0 !important; }
        &:first-child {
           @include max(xs){ width: 170px; max-width: 170px; flex: auto; }
        }
        &:last-child {
          @include max(xs){ flex: 1; width: auto; }
        }
      }
    }
  }
  // Logo
  .header-logo { float: left; max-width: 260px; width: 100%; display: block;
    @include max(xs){ text-decoration: underline; max-width: 170px; }
    .black { display: block;
      .page-form & { display: none;
        @include max(sm){ display: block; }
      }
      .page-scrolled & { display: block; }
      .page-error & { display: none; }
    }
    .white { display: none;
      .page-form & { display: block;
        @include max(sm){ display: none; }
      }
      .page-scrolled & { display: none; }
      .page-error & { display: block; }
    }
  }


  .page-scrolled & { background-color: $white; z-index: 6; padding: rem(15px) 0;
    @include max(xs){ padding: rem(10px) 0; }
    .header-logo {
      @include max(xs){ max-width: 160px; }
    }
  }
  .page-form & {
    @include max(sm){ background-color: $white; z-index: 6; padding: rem(15px) 0;}
    @include max(xs){ padding: rem(10px) 0; }
    .header-logo {
      @include max(xs){ max-width: 160px; }
    }
  }
}
