/* ---- Form ---- */

// Form elements Common
label { -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

textarea {
  &.form-control { min-height: 150px; padding-top: rem(15px);}
}

.form-control {
  height: $input-height; background: $input-color; box-shadow: none; font-size: rem(16px); -webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; appearance: none; color: $black; /*caret-color: $primary;*/ border-radius: 3px; padding-left: 20px;
  border: solid 1px $light-grey;min-height: 49px;
  &:focus { box-shadow: none; border-color: $green; color: $black; }

  &::-webkit-input-placeholder {
    color: $black; opacity: 0.5 !important;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $black; opacity: 0.5 !important;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $black; opacity: 0.5 !important;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $black; opacity: 0.5 !important;
  }
  &:-webkit-autofill {
   -webkit-box-shadow: 0 0 0px 1000px $white inset;
   transition: background-color 5000s ease-in-out 0s;
    &:hover, &:focus {
     -webkit-box-shadow: 0 0 0px 1000px $white inset;
     transition: background-color 5000s ease-in-out 0s;
   }
  }
  &[disabled] {
    background: transparentize($dark-grey,0.8); color: transparentize($text-color,0.5); cursor: not-allowed;
  }
}
// .input-group-addon {
//   background: $input-color; color: $white; border: 0; width: 40px; padding: 0;
//   button { background: none; border: none; width: 40px; height: $input-height; line-height: 42px; padding: 0; font-weight: bold; font-size: rem(16px); }
//   .blue & { background: $text-color }
// }

// Form group
.form-group { position: relative; margin-bottom: rem(30px);
  //@include max(xs){margin-bottom:15px};
  label { color: rgba($text-color,.6); font-weight: 300; font-size: rem(18px); margin-bottom: rem(10px); cursor: default; font-weight: normal; font-style: normal; line-height: 1.33;  margin-bottom: 20px; }
  .control-label {
    &.required {
      &:after { content: '*'; margin-left: 7px;}
    }
  }
  .tooltip { position: absolute; opacity: 1; border-radius: 50%; text-align: center; right: 0; top: 2px; width: 20px; height: 20px; background-color: $green; color: $white; font-size: rem(14px); }
  // Error
  &.has-error {
    .form-control { border-color: $danger; box-shadow: none;
      &:focus { border-color: $danger; }
    }
    &:after { position: absolute; @include zmdi; line-height: 1; right: 25px; -moz-osx-font-smoothing: grayscale; top: 65%;
    font-size: rem(20px); transform: translateY(-50%); color: $danger; content: "\f136"; pointer-events: none;}
  }
  // Success
  &.has-success {
    .form-control { border-color: $success; box-shadow: none;
      &:focus { border-color: $success; }
    }
    &:after { position: absolute; @include zmdi; line-height: 1; right: 25px; -moz-osx-font-smoothing: grayscale; top: 65%;
    font-size: rem(20px); transform: translateY(-50%); color: $success; content: "\f26b"; pointer-events: none;}
  }
  // Icon
  &.form-icon {
    position: relative;
    .form-control { padding-left: rem(45px); }
    &:before { display: inline-block; @include zmdi; content: "\f1f1";  color: transparentize($dark-grey,0.5); position: absolute; top: 50%; left: 15px; pointer-events: none; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); font-size: rem(18px); }
  }
}


// Input Radio
.form-radio {
  input[type="radio"] {
    display: inline; position: absolute; left: -9999px;
    &:focus {
      + label {
        color: $primary;
      }
    }
    &:checked {
      + label {
        .icon {
          &:before { opacity: 1; }
        }
      }
    }
    + label {
     font-weight: 500; cursor: pointer;
     .icon {
      display: inline-block; width: 14px; height: 14px; margin: rem(3px) rem(5px) 0 0; vertical-align: top; cursor: pointer; border-radius: 50%; background-color: $white; border: 1px solid transparentize($dark-grey,0.5); position: relative; -webkit-transition: background-color 0.4s linear; -o-transition: background-color 0.4s linear; -moz-transition: background-color 0.4s linear; transition: background-color 0.4s linear;
      &:before { transition: 0.3s; content: ''; display: block; width: 6px; height: 6px; background-color: $primary; border-radius: 50%; opacity: 0; position: absolute; top: 50%; transform: translateY(-50%); right: 3px; }
     }
     .label-align { display: inline-block; width: calc(100% - 30px); }
    }
  }
}

// Input Checkbox
.form-checkbox {
  input[type="checkbox"] {
    display: inline; position: absolute; left: -9999px;
    &:focus {
      + label {
        color: $primary;
      }
    }
    &:checked {
      + label {
        .icon {
          &:before { opacity: 1; }
        }
      }
    }
    + label {
     cursor: pointer; font-weight: 500;
     .icon {
      display: inline-block; width: 15px; height: 15px; margin: rem(3px) rem(5px) 0 0; vertical-align: top; cursor: pointer; background-color: $white; border: 1px solid transparentize($dark-grey,0.5); position: relative; -webkit-transition: background-color 0.4s linear; -o-transition: background-color 0.4s linear; -moz-transition: background-color 0.4s linear; transition: background-color 0.4s linear;
      &:before { transition: 0.3s; @include zmdi; opacity: 0; content: "\f26b"; display: block; color: $primary; position: absolute; top: 50%; transform: translateY( -50% ); }
     }
     .label-align { display: inline-block; width: calc(100% - 30px); }
    }
  }
}
.checkbox label { padding-left: 0; }

// Select
.form-select {
 position: relative;

 select {
  padding-right: rem(40px);
  &:-moz-focusring { /*color: transparent;*/ text-shadow: 0 0 0; }
  &:focus { box-shadow: none; border-color: $primary; outline: none; }
  &:focus::-ms-value { background: $input-color; color: $text-color; }
  &:required:invalid {
    color: transparentize($dark-grey,0.5);
  }
  option {
    &:invalid { color: transparentize($dark-grey,0.5);}
    &[selected][disabled] {
      display: none;
    }
  }
 }
 &:after { pointer-events: none; @include zmdi; position: absolute; right: 15px; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); color: $primary; content: "\f2f9"; }
}

// Select IE
// Appeareance None
select::-ms-expand { display: none; }
// Reboot IE Select normal
@media all and (min-width:0\0) and (min-resolution:.001dpcm) {
 .form-select select { padding-right: rem(5px); }
 .form-select:after { display: none; }
}

// Datetimepicker
.datetime {
  position:relative;
  &:after { @include zmdi; content: "\f332"; position: absolute; color: $primary; right: 15px; top: 50%; transform: translateY(-50%); font-size: rem(19px); pointer-events: none;}
  .form-control { cursor: pointer;}
}

.datepicker {
  width: 100%; max-width: 250px;
  z-index: 2;
  min-width: 250px;
  .datepicker-switch { text-transform: capitalize; transition: 0.3s;
    &:hover, &:focus { background: $primary; color: $white;}
  }
  .datepicker-decades .decade { line-height: 1.8em !important; }
  .table-condensed { width: 100%; }
  .prev, .next { color: $primary; cursor: pointer; background: transparent; transition: 0.3s;
    &:hover, &:focus { background: $primary; color: $white;}
  }
  // .next { text-align: right;}
  // .picker-switch { text-align: center; text-transform: capitalize }
  .dow { text-align: center; font-weight: 400; border-bottom: 1px solid $primary; }

  table {
    td, td.day {
      text-align: center; transition-duration: 1s; border-radius: 0; width: 30px; height: 30px; line-height: 30px;
      &.old, &.new { color: transparentize($dark-grey,0.5); }
      &.active { background: $primary !important; color: $white }
      &.day , &.hour, &.minute, &.second {
        &:hover {
          background-color: darken($primary, 10%); color: $white; cursor: pointer;
        }
      }
      &.today{
        position: relative;
        &:before { content: ''; display: inline-block; border: solid transparent; border-width: 0 0 7px 7px; border-bottom-color: $secondary; border-top-color: transparentize($dark-grey,0.2); position: absolute; bottom: 0; right: 0; }
      }
      span {
       display: inline-block; width: 54px; height: 54px; line-height: 54px; margin: 0; cursor: pointer; border-radius: 0;     border-radius: 0;
       &.active {
         background: $primary; color: $white;
         &:hover { background: $primary; color: $white; }
        }
        &:hover { background-color: darken($primary, 10%); color: $white }
      }
    }
  }
}

// Input File
// .form-file { width: 0.1px; height: 0.1px; opacity: 0; overflow: hidden; position: absolute; z-index: -1; display: none !important;
//   + label { font-size: rem(14px); font-weight: 700; text-overflow: ellipsis; white-space: nowrap; cursor: pointer; display: inline-block; overflow: hidden; padding: rem(10px) rem(20px);  color: $primary; border: 1px solid $primary; background-color: $white; padding: 0;
//     &:hover {
//       border-color: $secondary;
//       strong { background-color: $secondary; }
//     }
//     strong { height: 100%; color: $white; background-color: $primary; display: inline-block; padding: rem(10px) rem(20px); }
//     svg { width: 16px; height: 16px; vertical-align: middle; fill: currentColor; margin-top: rem(-4px); margin-right: rem(4px);}
//     span { width: 200px; min-height: 32px; display: inline-block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; vertical-align: top; background: $white; padding: rem(10px) rem(20px); }
//   }
//   &:focus , &.has-focus{
//     + label {
//       outline: none; border-color: $secondary;
//     }
//   }
// }
// Upload zone
.file-drop-area {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding: 20px 20px 20px 110px; text-align: left;
  border: 1px dashed rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  transition: 0.2s;
  margin-bottom: 20px;
  &::after {display: inline-block; font-size: 64px; color: $grey; }
  &.is-active {
    background-color: rgba(255, 255, 255, 0.03);
  }
}

.fake-btn { display: inline-block;
  flex-shrink: 0;
  // @extend .btn;
  // @extend .btn-primary;
}

.file-msg { display: block;
  color: rgba($white,.2); line-height: 20px; z-index: 5; margin-bottom: 40px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  &:focus {
    outline: none;
  }
}
