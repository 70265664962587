/* ---- Carousel ---- */
.carousel-home {
  margin-bottom: rem(45px);
  .carousel-inner {
    .carousel-item {
      @include bgc(center, center, $grey); height: 700px; overflow: hidden;
    }
  }
  // .carousel-control-prev, carousel-control-next , .carousel-indicators { display: none;}
  .carousel-caption {
    left: initial; right: initial; bottom: 55px; max-width: 485px; text-align: left; text-shadow: none;
  }
}
