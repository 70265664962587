/* ---- form ---- */
.form-wrap { display: flex; height: 100vh;
  @include max(sm){ flex-direction: column; min-height: 100vh; height: auto; }
  .aside { position: relative; width: 28vw; background-color: $green; height: 100%;
    padding-top: 180px;
    @include max(lg){ padding-top: 120px;}
    @include max(md){ min-width: 400px; padding-top: 120px; }
    @include max(sm){ width: auto; height: auto; min-width: 0; padding-top: 100px; }
    @include max(xs){ padding-top: 63px;}
    &::after { content: ''; position: absolute; left: 0; bottom: 0; width: 100%; max-height: 260px;  height: 100%; background-image: url('../../images/home-round-2.svg'); background-position: left bottom; background-size: contain; background-repeat: no-repeat;
      @include max(sm){ display: none; }
    }
    .steps { position: relative; max-width: calc(100% - 100px); margin: auto; @include icon(chevron-up,after);
      @include max(sm){ max-width: 100%; padding:0 20px; width: 100%; }
      &::after { color: $white; transform: rotate(180deg); position: absolute; right: 15px; top: 40px; font-size: 7px; display: none; pointer-events: none;
        @include max(sm){ display: block;}
        @include max(xs){ top: 30px; }
      }
      &.open {
        &::after { transform: rotate(0deg);}
      }
      .step { padding-left: 85px; position: relative; padding-top: 40px; padding-bottom: 40px; border-bottom: 1px solid $green-picto;
        @include max(sm){ display: none; padding-left: 80px; padding-top: 15px; padding-bottom: 15px; height: 90px; }
        @include max(xs){ padding-left: 70px; height: 80px; }
        &::before { content:''; position: absolute; left: 0; top: 38px; width: 60px; height: 60px; border-radius: 3px; background-color: $green-picto;
          @include max(lg){ width: 60px; height: 60px; left: 0; }
          @include max(md){ width: 60px; height: 60px; left: 0; }
          @include max(sm){ width: 60px; height: 60px; left: 0; top: 15px; }
          @include max(xs){ width: 50px; height: 50px; left: 0; top: 15px; }
        }

        .index { display: block; font-size: rem(16px); font-weight: 800; font-stretch: normal; font-style: normal;letter-spacing: 1.5px; color: $light-green; text-transform: uppercase;
          @include max(sm){}
          @include max(xs){ font-size: rem(12px); font-weight: 800; font-stretch: normal; font-style: normal; line-height: 1.67; letter-spacing: 1.29px; }
        }
        .name { display: block; font-size: rem(22px); font-weight: 600; font-stretch: normal; font-style: normal; line-height: 1.73; letter-spacing: normal; color: $white;
          @include max(sm){}
          @include max(xs){ font-size: rem(18px); font-weight: 600; font-stretch: normal; font-style: normal; line-height: 1.11;}
        }

        &.signin {
          &::before { background-image: url('../../images/dt-id.svg'); background-position: center; background-size: 35px 33px; background-repeat: no-repeat; }
        }
        &.import {
          &::before { background-image: url('../../images/dt-import.svg'); background-position: center; background-size: 38px 31px; background-repeat: no-repeat; }
        }
        &.download {
          &::before { background-image: url('../../images/dt-print.svg'); background-position: center; background-size: 32px 33px; background-repeat: no-repeat;}
        }
        &:not(.active) {
          * { opacity: .2; }
          &::before { opacity: .2; }
        }
        &.completed { cursor: pointer;
          * { opacity: .5; }
          &::before { opacity: .5; }
        }
        &.active {
          @include max(sm){ display: block; }
          * { opacity: 1; }
          &::before { opacity: 1; }
        }
        &:first-child { padding-top: 0;
          @include max(sm){ padding-top: 15px; }
          &::before { top: -2px;
            @include max(sm){ top: 15px; }
          }
        }
        &:last-child { border-bottom: none; }
      }
    }
  }
  .main-content { flex:1; justify-content: center; display: flex; height: 100%; overflow: hidden;
    padding-top: 180px; z-index: 5;
    @include  max(lg){ padding-top: 80px; }
    @media (max-width: 1279.98px){ padding-top: 120px; }
    @media (max-width: 1024px){ padding-top: 120px; }
    @include  max(sm){ height: auto; padding-top: 40px; }
    @include  max(xs){}
    .block { position: relative; width: 27vw; min-width: 400px;
      @include max(sm){ min-width: 0; width: auto;}
      .form-step { max-height: calc(100vh - 210px); overflow: scroll; padding-bottom: 60px;
        @include  max(lg){ max-height: calc(100vh - 110px); overflow: scroll;}
        @media (max-width: 1279.98px){ max-height: calc(100vh - 150px); overflow: scroll;}
        @media (max-width: 1023px){ max-height:none; overflow: auto; padding-bottom: 0;}
      }
      h2,.error-name { display: block; font-size: rem(32px); font-weight: bold; font-style: normal; line-height: 1.56; color: $green; margin-bottom: 10px; }
      .chapo { font-size: rem(18px); font-weight: normal; font-style: normal; line-height: 1.33; color: $dark-grey; margin-bottom: 10px;
        p { margin: 0; }
        + a, + .form { margin-top: 40px;
          @include max(lg){ margin-top: 20px;}
          @include max(md){ margin-top: 15px;}
          @include max(xs){ margin-top: 15px; }
        }
      }
      .text { font-size: rem(14px); font-weight: normal; font-style: normal; line-height: 1.43; color: $dark-grey; margin-bottom: 50px;
        @include max(lg){ margin-bottom: 29px; }
        @include max(md){ margin-bottom: 29px; }
        @include max(xs){ margin-bottom: 29px; }
        p { margin: 0; }
        a { @extend .link; }
      }
      .required-mentions { margin-top: -10px; font-size: rem(14px); font-weight: normal; color: $green; }
      .actions { margin-top: 25px;
        @include max(sm){ text-align: center; }
      }

      .box { position: relative; cursor: pointer; border-radius: 3px; padding: 20px 50px 20px 110px; border: solid 1px $light-grey; transition: .5s all ease;
        @include max(xs){ padding-left: 20px; padding-right: 20px; text-align: center; }
        .title { display: block; font-size: rem(21px); font-weight: bold; font-stretch: normal; font-style: normal; line-height: 0.95; margin: 0 0 10px; color: $text-color;
          @include max(xs){ font-size: rem(18px); margin: 0 0 5px; }
        }
        .text { display: block; font-size: rem(18px); font-weight: normal; font-stretch: normal; font-style: normal; line-height: 1.33; letter-spacing: normal; color: $grey; margin-bottom: 0;
          @include max(xs){ font-size: rem(16px); }
        }
        input[type="checkbox"]{ position: absolute; opacity: 0; }


        &.photo { border-color: $light-green;
          &::before { content:''; position: absolute; left: 33px; top: 50%; transform: translateY(-50%); width: 38px; height: 31px; background-position: center; background-size: contain; background-repeat: no-repeat; background-image: url('../../images/import-mobile.svg');
            @include max(xs){ display: inline-block; position: relative; left: auto; top: auto; transform: none; margin: 0px auto 10px; background-image: url('../../images/import-mobile.svg'); }
          }
          .title { color: $light-green; }
          .text { color: $light-green; }
          &:hover, &:focus, &.active, &.has-file { background-color: $light-green; border-color: $light-green;
            .title { color: $white }
            .text { color: $white;}
          }
          &:hover, &:focus, &.active, &.has-file { background-color: $light-green; border-color: $light-green;
            &::before { background-image: url('../../images/import-active.svg');
              @include max(xs){ background-image: url('../../images/import-mobile.svg');}
            }

            .title { color: $white;
              @include max(xs){ }
            }
            .text { color: $white;
              @include max(xs){ }
            }
          }
          &.disabled { border-color: $light-green; background-color: $white;
            .title { color: $light-green; }
            .text { color: $light-green; }
            &:hover,&.active, &:focus, &.has-file { background-color: $light-green; border-color: $light-green;
              &::before { background-image: url('../../images/import-active.svg');
                @include max(xs){ background-image: url('../../images/import-mobile.svg');}
              }

              .title { color: $white;
                @include max(xs){ }
              }
              .text { color: $white;
                @include max(xs){ }
              }
            }
          }
        }
        &.no-photo {
          &::before { content:''; position: absolute; left: 33px; top: 50%; transform: translateY(-50%); width: 38px; height: 31px; background-position: center; background-size: contain; background-repeat: no-repeat; background-image: url('../../images/no-photo.svg');
            @include max(xs){ display: inline-block; position: relative; left: auto; top: auto; transform: none;margin: 0px auto 10px;}
          }
          &:hover, &.active { background-color: $grey; border-color: $grey;
            .title { color: $white }
            .text { color: $white;}
          }
          &:hover,&.active {
            &::before { background-image: url('../../images/no-photo-hover.svg');}
          }
          &:hover {
            @include max(md){ background-color: $white; border-color: $light-grey; }
            .title {
              @include max(md){ color: $text-color; }
            }
            .text {
              @include max(md){ color: $grey;}
            }
            &::before {
              @include max(md){ background-image: url('../../images/no-photo.svg'); }
            }
          }
          &.active { background-color: $grey; border-color: $grey;
            .title { color: $white }
            .text { color: $white;}
          }
        }
      }
      .error-image p { color: $red; padding-left: 25px; position: relative; @extend .icon-alerte;
        &::before { position: absolute; left: 0; top: 0; }
      }
      .error-email p { color: $red; padding-left: 25px; position: relative; @extend .icon-alerte;
        &::before { position: absolute; left: 0; top: 0; }
      }
      .back-to-prev { cursor: pointer; font-size: rem(16px); font-weight: normal; font-style: normal; line-height: 1.25; letter-spacing: normal; color: $grey; padding-left: 38px; margin-top: 25px; display: inline-block; position: relative; @extend .icon-arrow-left;
        &::before { font-size: 11px; position: absolute; left: 0; top: 50%; transform: translateY(-50%); transition: .3s all ease; }
        &:hover, &:focus {
          color: $black;
          &::before { left: 5px; }
        }
      }


      .form-step { position: absolute; left: 0; top: 0; right: 0; display: block; opacity: 0; transform: translateY(100vh); transition: .5s all ease;
        @include max(sm){ transform: translateX(100vw); padding: 0 20px 45px;  }
        &.one {
          @include max(sm){ position: relative; }
        }
        &.complete { transform: translateY(-100vh);
          @include max(sm){ transform: translateX(-100vw); }
        }
        &.active { display: block; opacity: 1; transform: translateY(0vh);
          @include max(sm){ transform: translateX(0vw); }
        }
      }

      .error-block { display: none;
        @include max(sm){ padding-bottom: 40px; }
        .error-name { color: $red; line-height: 1.19;}
        p { margin-bottom: 20px; }
        .btn { margin-top: 30px; }
        >div { display: none;
          &.active { display: block; }
        }
      }
    }
  }
}
