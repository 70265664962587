/* ---- CMS ---- */
.page-cms {
  &:after { display: none;}
}
.cms-wrap {
  padding: 170px 30px 95px;
   @include max(lg){ padding: 140px 30px 95px; }
   @include max(sm){ padding: 130px 20px 35px; }
   @include max(xs){ padding: 80px 20px 35px; }

  // h1 {
  //   @include icon(kernix)
  //   &:before { font-size: rem(40px); }
  // }
  h1 { color: $black; font-size: rem(48px); font-weight: 900; margin-bottom: 30px;
    @include max(md){ margin-bottom: 35px; font-size: rem(38px); }
    @include max(sm){ margin-bottom: 40px; max-width: 400px;}
    @include max(xs){ font-size: rem(28px); font-weight: 900; line-height: 1.5; letter-spacing: normal; margin-bottom: 20px; }
  }
  h2 { color: $black; font-size: rem(38px);	font-weight: bold; line-height: normal; margin-bottom: 30px;
    @include max(lg){ font-size: rem(32px); margin-bottom: 32px; }
    @include max(xs){ font-size: rem(26px); margin-bottom: 20px; }
  }
  h3 { color: $black; font-size: rem(28px); font-weight: 600;	line-height: rem(38px); margin-bottom: 20px;
    @include max(lg){	font-size: rem(21px);  }
    @include max(md){ margin-bottom: 10px; }
    @include max(xs){ font-size: rem(26px); margin-bottom: 12px; line-height: 1.23; }
  }

  // Title
  // h1 { font-size: rem(32px); margin-bottom: rem(40px);}
  // h2 { font-size: rem(24px); margin-bottom: rem(5px);}
  // h3 { font-size: rem(20px); margin-bottom: rem(5px);}
  // h4 { font-size: rem(18px); margin-bottom: rem(5px);}
  // h5 { font-size: rem(16px); margin-bottom: rem(5px);}
  // h6 { font-size: rem(14px); margin-bottom: rem(5px);}

  // Paragraphe
  p {}

  // List
  // ul { padding-left: rem(30px); margin-bottom: 0; list-style: none;
  //   li {
  //     &:before {
  //     content:''; width: 4px; height: 4px; display: inline-block; border-radius: 50%; background: @brand-secondary; margin-right: rem(6px); margin-top: rem(12px); vertical-align: top;}
  //   }
  // }

  // Img
  img { height: auto !important;}


  // Link
  a {
  }



  // Table
  table {
    @extend .table;
    @extend .table-bordered;
    * { border-top: none !important; border-bottom: none !important; }
    thead { color: $white; background-color: $green; }
    tbody {
      tr {
        &:nth-child(odd){ background-color: $white; }
        &:nth-child(even){ background-color: #f2f4f6; }
        &:last-child { border-bottom:1px solid #d8d7d5 !important;}
      }
    }
  }
  .cms-content {
    a { @extend .link; }
  }
}
