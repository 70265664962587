/* ---- Footer ---- */
.footer-wrap { position: fixed; bottom: 0; left: 0; right: 0; z-index: 5;
  //@extend .clearfix;
  padding: rem(20px) 0;
  @include max(sm){ position: relative; bottom: auto; left: auto; right: auto; }
  .page-form & {
    @include max(sm){ border-top: 1px solid $light-grey; }
  }
  .custom-container {
    @include max(xs){ padding: 0 10px !important; }
    .row {
      @include max(xs){ margin:0 -10px; }
    }
  }
  .footer-links { font-size: rem(14px);	line-height: rem(22px);
    @include max(sm){ padding-top: 8px; }
    @include max(xs){ text-align: center; margin-bottom: 25px; display: block; padding-top: 0; }
    .mentions { position: relative; margin-right:18px;
      @include max(sm){ color: $white; }
      .page-cms & { color: $dark-grey !important;}
      &::after { content: ''; position: absolute; right: -9px; height: 15px; width: 1px; background-color: $light-grey; top: 50%; transform: translateY(-50%);
        @include max(xs){ display: none; }
      }
      + a { margin-right:18px;
        &::after { content: ''; position: absolute; right: -9px; height: 15px; width: 1px; background-color: $light-grey; top: 50%; transform: translateY(-50%);
          @include max(xs){ background-color: rgba($white,.2); }
          .page-cms & { background-color: $light-grey !important;}
        }
      }
      .page-form &, .page-cms & { display: block; color: $white;
        @include max(sm){ color: $dark-grey !important; }
        &::after { display: none; }
        + a {
          &::after { background-color: rgba($white,.2); }
        }
      }
     .page-error & { color: $white; }
    }
    a { display: inline-block; position: relative; color: $dark-grey;
      &:hover, &:focus { text-decoration: underline;}
      @include max(sm){ color: $white; }
      .page-form &, .page-error & { color: $white; }
      .page-form &, .page-cms & {
        @include max(sm){ color: $dark-grey; }
      }
    }
  }
  .socials {
    .social-link { display: inline-block; border-radius: 50%; width: 35px; height: 35px; border: 1px solid rgba($white,.2); color: $white; line-height: 35px; text-align: center; margin: 0 3px 0;
      &:hover, &:focus { background-color: $white; color: $green; border-color: $white; }
      .icon-facebook { font-size: 14px; display: inline-block;}
      .icon-twitter { font-size: 10px; display: inline-block;}
      .icon-linkedin { font-size: 11px; display: inline-block;}
    }
    .page-form &, .page-cms & {
      .social-link { border: 1px solid rgba($dark-grey,.2); color: $dark-grey;
        &:hover, &:focus { background-color: $green; color: $white; border-color: $green; }
      }
    }
  }

  .page-cms & { position: relative; }
}
