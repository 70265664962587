/* ---- General rules ---- */
:root {
  --tertiary: $tertiary;
}
html { height:100%; }
body { position: relative;
  font-stretch: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::after { position: absolute; bottom: 0; left: 0; right: 0; height: 50%; background-color: $green; z-index: -1;
    @include max(sm){ content: ''; }

  }
  &.page-form {
    &::after { display: none; }
  }
  &.modal-open {
    @include max(xs){ position: fixed; width: 100%; height: 100%; overflow: hidden;};
  }
}
h1 { @extend .titlefont; }
h1, h2, h3, h4, h5, h6 { margin-top: 0; line-height: 1.3;}
img { max-width: 100%; }
a {
  outline: 0;
  transition: 0.3s;
  &.url { word-break: break-all; }
  &:hover, &:active, &:focus { outline: 0; }
}
p {
  // font-size: rem(14px);
  word-wrap: break-word;
}
.text-break {
  @media screen and (min-width:0\0) { word-break: break-all;}
}
.custom-container {
  padding: 0 50px;
  @include max(sm){ padding: 0 20px; }
  @include max(xs){ padding: 0 10px; }
}

// Selection Text
::-moz-selection { color: $white; background: $primary; }
::selection { color: $white; background: $primary; }

// Container
// $gutter: 15px;
// .row { margin-left: -$gutter; margin-right: -$gutter; }
// [class*="col-"]{ padding-left: $gutter; padding-right: $gutter; }
.row-reverse {
  @include max(sm) {
    display: flex; flex-direction: column-reverse;
  }
}

// Modal
.close {
  &:hover, &:focus { outline: none;}
}

// Fix Google Map
.google-map [aria-hidden] { display: block }

// Google captcha
.grecaptcha-badge { box-shadow: rgba(100, 100, 100, 0.2) 0px 0px 8px; z-index: 5;
  @include min(md){ bottom: 50% !important; }
  @include max(sm){ bottom: 120px !important; }
  @include max(xs){ bottom: 20px !important; }
}


// Loader
.loader-wrap {
  position: fixed; z-index: 10; top: 0; left: 0; right: 0; bottom: 0; background: rgba($green,0.8); display: none;
  &.active { display: block;}

  .loader {
    position: absolute; font-size: 60px; left: calc(50% - 30px); top: 30%; transform: translate(-50%,-50%);
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      border: .1em solid transparent;
      border-bottom-color: $white;
      top: 0;
      left: 0;
      animation: 1s loader linear infinite;
    }
    &:before {
      width: 1em;
      height: 1em;
    }
    &:after {
      width: .8em;
      height: .8em;
      top: .1em;
      left: .1em;
      animation-direction: reverse;
    }
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
