/* ---- 404 ---- */
.error-wrap { position: relative; display: flex; flex-direction: column; justify-content: center; height: 100vh; text-align: center; background-color: $green; overflow: hidden;
  @include max(sm){ min-height: 100%; flex: 1; height:auto; padding-top: 50px; }
  &::before { content: ''; position: absolute; left: 0; bottom: 80px; height: 419px; width: 111px; background-image: url('../../images/error-circle.svg'); background-size: contain; background-position: left center; background-repeat: no-repeat; pointer-events: none;
    @include max(sm){ display: none; }
  }
  &::after { content: ''; position: absolute; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%); height: 32vw; width: 66vw; background-image: url('../../images/404.svg'); background-size: contain; background-position: left center; background-repeat: no-repeat; pointer-events: none; max-width: 955px;
    @include max(xs){ height: 100vw; width: 120vw; }
  }
  .container {
    &::after { content: ''; position: absolute; left: 60%; bottom: 0; transform: translateX(-50%); width: 398px; height: 235px; background-image: url('../../images/error-round.svg'); background-size: contain; background-position: left center; background-repeat: no-repeat; pointer-events: none; max-width: 955px;
      @include max(sm){ display: none; }
    }
  }
  .content { position: relative; z-index: 3;
    h2 { font-size: rem(38px); font-weight: bold; font-style: normal; line-height: 1.32; margin-bottom: 18px; color: $white;
      @include max(xs){ font-size: rem(26px); line-height: 1.46;}
    }
    p { font-size: rem(24px); font-weight: 500; font-stretch: normal; font-style: normal; line-height: 1.42; margin-bottom: 48px; color: $white;
      @include max(xs){ font-size: rem(21px); line-height: 1.33; }
    }
    .btn {}
  }
}
