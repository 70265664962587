/* ---- Button ---- */
.btn {
  // background-color: $grey;
  border: 0;
  border-radius: 3px;
  font-size: rem(18px);	font-weight: bold; line-height: 26px;
  min-height: $btn-height;
  text-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-decoration: none;
  white-space: normal;
  padding: rem(12px) rem(30px);
  background-image: none;
  white-space: normal;
  box-shadow: initial;
  transition: 0.3s;
  @include max(md){ font-size: rem(16px); }
  @include max(xs){ min-height: 44px; padding: rem(12px) rem(30px); line-height: 1.22; }
  &:focus , &:active , &:active:focus { outline: none; box-shadow: none; }
  &.btn-full { width:100%; }
  &.btn-icon {
    position: relative; padding-left: rem(45px);
    &:before {
      @include zmdi; content: "\f1f1"; font-size: rem(18px); display: inline-block; position: absolute; top: 50%; left: 15px; transform: translateY(-50%);
    }
  }
  &.btn-primary {
    // color: $white; background-color: $primary;
    &:focus, &:hover, &:active:focus { background-color: transparentize($primary, 0.2); border-color: transparentize($primary, 0.2);}
  }
  &.btn-secondary  {
    // color: $white; background-color: $secondary;
    &:focus, &:hover, &:active:focus { background-color: transparentize($secondary, 0.2); border-color: transparentize($secondary, 0.2)}
  }
  &.btn-tertiary {
    color: $white; background-color: $tertiary;
    &:focus, &:hover, &:active:focus { background-color: transparentize($tertiary, 0.2); border-color: transparentize($tertiary, 0.2);}
  }
  &.btn-orange {
    color: $white; background-color: $orange; border: 1px solid $orange;
    &:focus, &:hover, &:active:focus { color: $orange; background-color: $white; }
  }
  &.btn-red {
    color: $white; background-color: $red; border: 1px solid $red;
    &:focus, &:hover, &:active:focus { color: $red; background-color: $white; }
  }
  &.btn-outline {
    border: 1px solid $white; background-color: transparent; color: $white;
    &:focus, &:hover, &:active:focus { background-color: $white; color: $green; }
  }
  &.btn-help { min-height: 44px; padding: rem(9px) rem(30px); margin-top: 10px;
    @include max(xs){ padding: rem(12px) rem(9px); color: $green; border: 1px solid $green; font-size: rem(14px); margin-top: 0; min-height: 40px; }
    &:focus, &:hover, &:active:focus {
      @include max(xs){ background-color: $green; color: $white; }
    }
    .page-scrolled &, .page-cms & { color: $green; border: 1px solid $green;
      &:focus, &:hover, &:active:focus { background-color: $green; color: $white; }
      @include max(xs){ height: 35px; min-height: 35px; padding: rem(8px) rem(9px); margin-top:5px; }
    }
    .page-home & {
      @include max(sm){ color: $green; border: 1px solid $green;}
      &:focus, &:hover, &:active:focus {
        @include max(sm){ background-color: $green; color: $white; }
      }
    }
    .page-form & {
      @include min(md){ color: $green; border: 1px solid $green;}
      &:focus, &:hover, &:active:focus {
        @include min(md){ background-color: $green; color: $white; }
      }
    }
    .page-error & { color: $white; border: 1px solid $white;
      &:focus, &:hover, &:active:focus {  background-color: $white; color: $green; }
    }
  }
  &[disabled] {
    pointer-events: none; opacity: 0.5;
  }
}
.link {
  @extend .text-break;
  @media screen and (min-width:0\0) { word-break: break-all;}
  position: relative; display: inline-block; font-weight: normal; color: $green;
  &::after {
    @include min(sm){ content: ''; position: absolute; bottom: 2px; left: 0; right: 0; height: 1px; background-color: $green; transition: .3s all ease; }
  }
  &:hover,&:focus, &:active:focus {
     text-decoration: none; color: $green;
    &::after {
      @include min(md){ bottom:-1px; }
    }
  }
}
