/* ---- Home ---- */
body { display: flex; flex-direction: column; min-height: 100vh; }
.home-wrap { display: flex; min-height: 100vh; flex-direction: row; flex:1;
  @include max(sm){ min-height: auto; height: auto; flex-direction: column; }
  .left-bloc { position: relative; width: 50%; background-color: $white; display: flex; justify-content: space-around; padding: 170px 30px 95px;
    @include max(lg){ padding: 140px 30px 95px;}
    @include max(sm){ padding: 130px 20px 35px; width: auto; display: block; }
    @include max(xs){ padding: 80px 20px 35px; width: auto; overflow: hidden; }
    &::before { content: ''; position: absolute; right: 0; top: 0; width: 100%; height: 100%; max-height: 650px; background-image: url('../../images/home-forme.svg'); background-position: top right; background-size: contain; background-repeat: no-repeat;
      @include max(xs){ height: 110%; right: -20px; }
    }
    &::after { content: ''; position: absolute; left: 0; bottom: 0; width: 100%; height: 100%; max-height: 250px;  background-image: url('../../images/home-round.svg'); background-position: left bottom; background-size: contain; background-repeat: no-repeat;
      @include max(sm){ display: none; }
    }
    .content { max-width: 580px; position: relative; z-index: 3;
      @include max(sm){ max-width: none; }
      h1 {	color: $black; font-size: rem(48px); font-weight: bold; margin-bottom: 55px;
        @include max(md){ margin-bottom: 35px; font-size: rem(38px); }
        @include max(sm){ margin-bottom: 40px; max-width: 400px;}
        @include max(xs){ font-size: rem(28px); font-weight: 900; line-height: 1.5; letter-spacing: normal; margin-bottom: 20px; }
      }
      .btn {  }
    }
  }
  .right-bloc { position: relative; width: 50%; background-color: $green; display: flex; justify-content: space-around; padding: 170px 30px 95px;
    @include max(lg){ padding: 140px 30px 95px;}
    @include max(sm){ padding: 26px 20px 20px; width: auto; position: static; }
    @include max(xs){ }
    &::before { content: ''; position: absolute; right: 0; top: 0; width: 100%; height: 100%; max-height: 450px;  background-image: url('../../images/home-round-3.svg'); background-position: top right; background-size: contain; background-repeat: no-repeat; }
    &::after { content: ''; position: absolute; left: 0; bottom: 0; width: 100%; max-height: 260px;  height: 100%; background-image: url('../../images/home-round-2.svg'); background-position: left bottom; background-size: contain; background-repeat: no-repeat;
      @include max(xs){ max-width: 260px; }
    }
    .content { max-width: 500px; position: relative; z-index: 3;
      @include max(sm){ max-width: none; }
      h2 { color: $white; font-size: rem(38px);	font-weight: bold; line-height: normal; margin-bottom: 52px;
        @include max(lg){ font-size: rem(32px); margin-bottom: 32px; }
        @include max(xs){ font-size: rem(26px); margin-bottom: 20px; }
      }
      dl { padding-left: 110px; margin: 0;
        @include max(lg){ padding-left: 100px; }
        @include max(md){ padding-left: 90px; }
        @include max(xs){ padding-left: 0; }
        dt { position: relative; color: $white;	font-size: rem(28px); font-weight: 600;	line-height: rem(38px); margin-bottom: 20px;
          @include max(lg){	font-size: rem(21px);  }
          @include max(md){ margin-bottom: 10px; }
          @include max(xs){ font-size: rem(26px); margin-bottom: 12px; line-height: 1.23; }
          &::before { content:''; position: absolute; left: -110px; top: 15px; width: 80px; height: 80px; border-radius: 3px; background-color: $green-picto;
            @include max(lg){ width: 70px; height: 70px; left: -100px; }
            @include max(md){ width: 60px; height: 60px; left: -90px; }
            @include max(xs){ display: block; position: relative; left: auto; top: auto; width: 60px; height: 60px; margin-bottom: 18px; }
          }
          &.signin {
            &::before { background-image: url('../../images/dt-id.svg'); background-position: center; background-size: 45px 43px; background-repeat: no-repeat;
              @include max(xs){ background-size: 35px 33px; }
            }
          }
          &.import {
            &::before { background-image: url('../../images/dt-import.svg'); background-position: center; background-size: 50px 42px; background-repeat: no-repeat;
              @include max(xs){ background-size: 38px 31px; }
            }
          }
          &.download {
            &::before { background-image: url('../../images/dt-print.svg'); background-position: center; background-size: 43px 45px; background-repeat: no-repeat;
              @include max(xs){ background-size: 32px 33px; }
            }
          }
        }
        dd { color: $white;	font-size: rem(21px);	line-height: rem(28px); margin-bottom: 50px;
          @include max(lg){	font-size: rem(16px);  margin-bottom: 30px; }
          @include max(md){ margin-bottom: 30px; }
          @include max(xs){ font-size: rem(18px); margin-bottom: 40px; line-height: 1.33; }
          a { position: relative; @extend .btn; @extend .btn-outline; margin-top: 19px; font-size: rem(14px); display: block; width: fit-content; font-weight: normal;
            @include min(lg){ margin-top: 19px; }
            @include min(sm){ display: block; color: $white; font-size: rem(14px); line-height: rem(20px); margin-top: 10px; padding: 0; border:none; min-height: 0; text-align: left; text-decoration: none; }
            &::before { content: '* '; display: inline-block; margin-right: 3px; }
            &::after {
              @include min(sm){ content: ''; position: absolute; bottom: 0px; left: 11px; right: 0; height: 1px; background-color: $white; transition: .3s all ease; }
            }
            &:hover,&:focus, &:active:focus {
              @include min(md){ text-decoration: none; background-color: transparent; color: $white; border:none; }
              &::after {
                @include min(md){ bottom:-3px; }
              }
            }
          }
          &:last-child { margin-bottom: 0; }
        }
      }
    }
  }
}
